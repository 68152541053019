export const navItems = [
  {
    title: true,
    name: 'Frakcio Superadmin'
  },
  {
    name: 'Condominios',
    url: '/condominios',
    icon: 'fa fa-home'
  },
  {
    name: 'Cortes',
    url: '/cortes',
    icon: 'fa fa-list'
  },
  {
    name: 'Residentes',
    url: '/resident',
    icon: 'fa fa-user'
  }
];
